import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import ExternalLinkComponent, { ExternalLinkComponentType } from './ExternalLinkComponent';
import InternalCrossReference, { InternalCrossReferenceComponent } from './InternalCrossReferenceComponent';
import ExternalCrossReference, { ExternalCrossReferenceComponentType } from './ExternalCrossReferenceComponent';
import linkUtils, { LinkData, LinkModel } from './generic/linkService';
import EditorStore from '../../../flux/editor/EditorStore';
import UnitConceptStore from '../../../flux/editor/UnitConceptStore';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import { IUnitConceptMap } from 'mm-types';
import { linkHelper, LinkHelperData } from '../utils/tinyFacade/tinyLinkHelper';
import LinkStore from '../../../flux/editor/LinkStore';

export type Props = {
  onClose: () => void;
  onSave?: (linkData: LinkData) => void;
  linkData?: any;
  isHotspot?: boolean;
  isDuRefLink?: boolean;
  templateHtml?: HTMLElement;
};

const LinkModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [model, setModel] = useState<Partial<LinkModel> | null>(null);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  let internalCrossReferenceRef = useRef<InternalCrossReferenceComponent>(null);
  const externalCrossReferenceRef = useRef<ExternalCrossReferenceComponentType>(null);
  const externalLinkRef = useRef<ExternalLinkComponentType>(null);
  useEffect(() => {
    let linkData: LinkHelperData | null | undefined = null;

    if (props.linkData) {
      linkData = props.linkData;
    } else if (EditorStore.getEditor().getActiveEditorFacade() && !props.templateHtml) {
      linkData = EditorStore.getEditor().getActiveEditorFacade()?.getLinkData();
    } else if (props.templateHtml) {
      linkData = linkHelper(undefined, props.templateHtml).data;
    }

    if (linkData) {
      const linkModel: Partial<LinkModel> = linkUtils.fromLinkToModel(linkData);
      // Make sure for hot spots to never manage links
      if (props.isHotspot) {
        linkModel.managed = false;
      }
      setIsOpen(true);
      setModel(linkModel);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keyup', onKeypress);
    } else if (!isOpen && model) {
      props.onClose();
    }
  }, [isOpen]);

  const onKeypress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      dismiss(true);
    }
  };

  const dismiss = (isCancel = false) => {
    document.removeEventListener('keyup', onKeypress);
    EditorStore.getEditor().silentReFocus({ preventCursorReposition: true });
    if (isCancel) {
      LinkStore.triggerCloseLinkModal();
    }
    setIsOpen(false);
  };

  const disableSubmit = (disabled: boolean) => {
    setDisabledSubmit(disabled);
  };
  const getLinkForm = () => {
    const selectedUnits = EditorStore.getSelectedUnits();
    let selectedVariantsMap: IUnitConceptMap | null = null;

    if ((selectedUnits && selectedUnits.length > 0) || model?.selectedVariant !== undefined) {
      selectedVariantsMap = UnitConceptStore.getUnitVariantMap(selectedUnits[0].uid);
    }
    if (model?.type === 'document') {
      return (
        <InternalCrossReference
          model={model}
          onUpdateModel={(partial) => setModel({ ...model, ...partial })}
          onFetching={(disabled) => disableSubmit(disabled)}
          showValidation={showValidation}
          selectedVariantsMap={selectedVariantsMap!}
          isHotspot={props.isHotspot}
          isDuRefLink={props.isDuRefLink}
          ref={internalCrossReferenceRef}
        />
      );
    } else if (model?.type === 'cross-document') {
      return (
        <ExternalCrossReference
          model={model}
          onUpdateModel={(partial) => setModel({ ...model, ...partial })}
          onFetching={(disabled) => disableSubmit(disabled)}
          showValidation={showValidation}
          ref={externalCrossReferenceRef}
          selectedVariantsMap={selectedVariantsMap!}
          isHotspot={props.isHotspot}
          isDuRefLink={props.isDuRefLink}
        />
      );
    } else if (model) {
      return (
        <ExternalLinkComponent
          model={model}
          showValidation={showValidation}
          ref={externalLinkRef}
          onModelUpdated={(partial) => setModel({ ...model, ...partial })}
          isHotspot={props.isHotspot}
        />
      );
    }
  };

  const getWidth = () => {
    if (model?.type === 'cross-document') {
      return '1200px';
    } else {
      return '850px';
    }
  };

  const save = () => {
    const isValid =
      model?.type === 'document'
        ? internalCrossReferenceRef.current?.isValid()
        : externalCrossReferenceRef.current?.isValid() || externalLinkRef.current?.isValid();
    if (isValid) {
      if (model) {
        if (props.onSave) {
          props.onSave(Object.assign({}, props.linkData, linkUtils.fromModelToLink(model)));
        } else {
          dismiss();
          if (props.templateHtml) {
            LinkStore.triggerCloseLinkModal(model);
          } else {
            const editor = EditorStore.getEditor();
            if (editor.isFocused()) {
              editor.silentReFocus();
            }
            editor.getActiveEditorFacade()!.insertLink(linkUtils.fromModelToLink(model, props.isDuRefLink));
          }
        }
      }
    } else {
      setShowValidation(true);
    }
  };

  return (
    <Dialog
      className={'link-dialog'}
      style={{ zIndex: 999, width: '100%' }}
      contentStyle={{ width: getWidth(), maxWidth: getWidth(), height: '98%' }}
      title={model?.isUpdate ? `Edit a ${props.isDuRefLink ? 'DuRef ' : ''}Link` : `Insert a ${props.isDuRefLink ? 'DuRef ' : ''}Link`}
      autoDetectWindowHeight={true}
      autoScrollBodyContent={true}
      actions={[
        <FlatButton
          key={1}
          label="Cancel"
          onClick={() => {
            LinkStore.triggerCloseLinkModal();
            dismiss(true);
          }}
        />,
        <FlatButton
          key={2}
          label={props.isHotspot ? 'Add' : 'Save'}
          disabled={disabledSubmit}
          onClick={() => {
            save();
          }}
        />
      ]}
      open={isOpen}
    >
      <div className="link-dialog-inner">{getLinkForm()}</div>
    </Dialog>
  );
};
export default LinkModal;
